@import "config";
@import "base/print";

html, body
{
	margin: 0;
	padding: 0;
	text-align: center;
}

body
{
	font-family: $fontBase;
	font-size: $fontSize;
	line-height: $fontHeight;
	margin: 0 auto;
	padding: 0;
	text-align: left;
	max-width: 960px;
}

h1, h2, h3, h4, h5, h6
{
	font-size: $fontSize + 6;
	font-weight: 700;
	line-height: $fontHeight + 6;
	margin: 0;
	padding: 0 0 15px 0;
}

h3
{
	font-size: $fontSize + 4;
	line-height: $fontHeight + 4;
	padding-bottom: 10px;
}

h4
{
	font-size: $fontSize + 3;
	line-height: $fontHeight + 3;
	padding-bottom: 10px;
}

h5, h6
{
	font-size: $fontSize;
	line-height: $fontHeight;
	padding-bottom: 5px;
}

hr
{
	display: none;
}

strong, b
{
	font-weight: 600;
}

// LAYOUT

// header
.l-header
{
	border-bottom: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	overflow: hidden;
	padding: 0 0 15px 0;
	&__logo
	{
		float: left;
		margin: 0;
		padding: 0 15px 0 0;
		width: 180px;
	}
	&__bailey
	{
		color: $colorGrey;
		float: right;
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: 0;
		padding: 10px 0;
	}
}

// content
.l-content
{
	margin: 0;
	padding: 15px 0;
	&__title
	{
		margin: 0;
		padding: 0 0 20px 0;
		&__primary
		{
			font-size: $fontSize + 10;
			line-height: $fontHeight + 10;
			margin: 0;
			padding: 0;
		}
	}
	&__summary
	{
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
	}
}

// contact
.l-contact
{
	border-top: 1px solid $colorDivider;
	margin: 15px 0 0 0;
	padding: 15px 0 0 0;
	text-align: center;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: 0;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			list-style: none;
			margin: 0;
			padding: 5px 10px;
		}
	}
}

// MODULES

// print grid
.print-grid
{
	font-size: 0;
	line-height: 0;
	margin: -10px 0 0 -10px;
	padding: 0 0 15px 0;
	&__col
	{
		@include box-sizing();
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 10px 0 15px 10px;
		vertical-align: top;
		width: 100%;
		&--2
		{
			width: 50%;
		}
		&--3
		{
			width: 33.3333%;
		}
		&--4
		{
			width: 25%;
		}
		&--5
		{
			width: 20%;
		}
	}
}

// banner
.m-banner
{
	background: none !important;
	margin: 0;
	padding: 0 0 15px 0;
	&__menu
	{
		list-style: none;
		margin: 0;
		padding: 0;
		&__item
		{
			float: none !important;
			list-style: none;
			margin: 0;
			padding: 0;
			position: static !important;
			width: auto !important;
		}
	}
}

// usps
.m-usp
{
	border-bottom: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -1px 0 0 -1px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			color: $colorGrey;
			display: inline-block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			list-style: none;
			margin: 0;
			padding: 1px 0 0 1px;
			text-align: center;
			vertical-align: top;
			width: 33.3333%;
			&__line-1
			{
				display: block;
			}
		}
	}
}

// banded block
.m-banded-block
{
	background: none !important;
}

// section block
.m-section-block
{
	margin: 0;
	padding: 0;
	&__title
	{
		margin: 0;
		padding: 0 0 15px 0;
		text-align: center;
	}
	&__content
	{
		margin: 0;
		padding: 0;
		text-align: left;
	}
}

// header
.m-header
{
	border-bottom: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	&__image
	{
		float: left;
		margin: 0;
		padding: 0 15px 0 0;
		max-width: 120px;
	}
	&__content
	{
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
	&__title
	{
		color: $colorBase;
		font-size: $fontSize + 10;
		font-weight: 400;
		line-height: $fontHeight + 10;
		margin: 0;
		padding: 0 0 10px 0;
	}
}

// category product
.m-category-product
{
	margin: 0 auto;
	padding: 0;
	text-align: left;
	max-width: 580px;
	&__image
	{
		margin: 0;
		padding: 0 0 10px 0;
		&__image
		{
			display: block;
			margin: 0;
			padding: 0;
		}
		&__flag
		{
			display: none;
		}
	}
	&__content
	{
		@include min-height(70px);
		margin: 0;
		padding: 0 0 10px 0;
	}
	&__title
	{
		font-size: $fontSize;
		font-weight: 600;
		line-height: $fontHeight;
		margin: 0;
		padding: 0;
	}
	&__price
	{
		font-size: $fontSize + 5;
		font-weight: 700;
		line-height: $fontHeight + 5;
		margin: 0;
		padding: 0;
		&__list
		{
			color: $colorPrimary;
			margin: 0;
			padding: 0;
			&--sale
			{
				color: $colorRed;
			}
		}
		&__other
		{
			color: $colorGrey;
			font-size: $fontSize - 2;
			font-weight: 400;
			line-height: $fontHeight - 2;
			margin: 0;
			padding: 0;
			&--consumer
			{
				display: block;
			}
		}
	}
}

// product
.m-product
{
	@include min-height(420px);
	margin: 0;
	padding: 0 0 0 50%;
	position: relative;
	z-index: 1;
	&__gallery
	{
		left: 0;
		margin: 0 auto;
		padding: 0;
		position: absolute;
		top: 0;
		max-width: 48%;
		z-index: 1;
		&__menu
		{
			margin: 0;
			padding: 0;
			&__item
			{
				display: none;
				margin: 0;
				padding: 0;
				position: static !important;
				width: auto !important;
				z-index: 1;
				&:first-child
				{
					display: block;
				}
			}
			&__image
			{
				display: block;
				margin: 0;
				padding: 0;
				position: relative;
				z-index: 1;
			}
			&__zoom
			{
				display: none;
			}
		}
	}
	&__cost
	{
		margin: 0;
		padding: 0;
	}
	&__section
	{
		&__title
		{
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
		}
	}
	&__spec
	{
		&__table
		{
			table
			{
				border-collapse: collapse;
				margin: 0;
				padding: 0;
				tbody
				{
					tr
					{
						th, td
						{
							border: 0;
							border-bottom: 1px solid $colorDivider;
							border-top: 1px solid $colorDivider;
							padding: 10px;
							text-align: left;
							vertical-align: top;
						}
						th
						{
							color: $colorGrey;
							font-weight: 400;
							width: 150px;
						}
					}
				}
			}
		}
	}
}

// history
.m-history
{
	margin: 0 auto;
	padding: 0;
	&__timeline
	{
		display: none;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block !important;
			float: none !important;
			font-size: $fontSize;
			line-height: $fontHeight;
			list-style: none;
			margin: 0;
			padding: 15px 0 15px 15px;
			position: static !important;
			vertical-align: top;
			width: 50% !important;
		}
	}
}

// history event
.m-history-event
{
	margin: 0;
	padding: 0;
	&__title
	{
		color: $colorBase;
		font-size: $fontSize + 5;
		font-weight: 600;
		line-height: $fontHeight + 5;
		margin: 0;
		padding: 0 0 10px 0;
	}
}

// blog category
.m-blog-category
{
	margin: 0;
	padding: 0 0 15px 0;
	&__menu
	{
		list-style: none;
		margin: 0;
		padding: 0;
		&__item
		{
			border-top: 1px solid $colorDivider;
			list-style: none;
			margin: 15px 0 0 0;
			padding: 15px 0 0 0;
			&:first-child
			{
				border-top: 0;
				margin-top: 0;
				padding-top: 0;
			}
		}
	}
	&__post
	{
		margin: 0;
		padding: 0;
		&__picture
		{
			float: left;
			margin: 0;
			padding: 0 15px 0 0;
			max-width: 120px;
			&__image
			{
				display: block;
				margin: 0;
				padding: 0;
			}
		}
		&__content
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
		}
		&__title
		{
			font-size: $fontSize + 3;
			font-weight: 600;
			line-height: $fontHeight + 3;
			margin: 0;
			padding: 0 0 10px 0;
		}
		&__meta
		{
			font-size: 0;
			line-height: 0;
			list-style: none;
			margin: 0;
			padding: 5px 0 0 0;
			&__item
			{
				border-right: 1px solid $colorDivider;
				@include box-sizing();
				color: $colorGrey;	
				display: inline-block;
				font-size: $fontSize - 2;
				line-height: $fontHeight - 4;
				margin: 0 10px 0 0;
				padding: 0 10px 0 0;
				vertical-align: top;
				&:last-child
				{
					border-right: 0;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}
}

// blog post
.m-blog-post
{
	margin: 0;
	padding: 0 0 15px 0;
	&__meta
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -10px 0 0 0;
		padding: 0 0 20px 0;
		&__item
		{
			border-right: 1px solid $colorDivider;
			@include box-sizing();
			color: $colorGrey;	
			display: inline-block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 4;
			margin: 0 10px 0 0;
			padding: 0 10px 0 0;
			vertical-align: top;
			&:last-child
			{
				border-right: 0;
				margin-right: 0;
				padding-right: 0;
			}
		}
	}
	&__image
	{
		margin: 0;
		padding: 0 0 20px 0;
		&__image
		{
			margin: 0;
			padding: 0;
		}
	}
}

// contact details
.m-contact-details
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;
	}
	&__group
	{
		@include box-sizing();
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 15px 0 15px 15px;
		vertical-align: top;
		width: 50%;
	}
	&__address
	{
		margin: 0;
		padding: 0;
	}
	&__hours
	{
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		&__title, &__data
		{
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 5px;
		}
		&__title
		{
			clear: both;
			float: left;
			width: 90px;
			&:after
			{
				content: ":";
			}
		}
	}
	&__item
	{
		margin: 0;
		padding: 5px 0 0 0;
		b
		{
			display: inline-block;
			padding-right: 5px;
			width: 90px;
		}
		&:first-child
		{
			padding-top: 0;
		}
	}
}

// basket
.m-basket
{
	margin: 0;
	padding: 0 0 15px 0;
	&__table
	{
		table
		{
			margin: 0;
			padding: 0;
			th, td
			{
				margin: 0;
				padding: 10px;
			}
			tbody
			{
				tr
				{
					th, td
					{
						font-weight: normal;
						.details
						{
							margin: 0;
							padding: 0;
							&__title
							{
								font-size: $fontSize;
								line-height: $fontHeight;
								margin: 0;
								padding: 0;
							}
							&__info
							{
								color: $colorGrey;
								font-size: $fontSize - 2;
								line-height: $fontHeight - 2;
								list-style: none;
								margin: 0;
								padding: 5px 0 0 0;
								&__item
								{
									list-style: none;
									margin: 0;
									padding: 0;
									b
									{
										font-weight: 400;
									}
								}
							}
						}
						&.price, &.quantity, &.total
						{
							width: 100px;
						}
					}
					&.discount
					{
						th, td
						{
							background: $colorOffWhite;
							border-bottom: 1px solid $colorDivider;
							border-top: 1px solid $colorDivider;
							font-size: $fontSize - 1;
							font-style: italic;
							line-height: $fontHeight - 1;
							margin: 0;
							padding: 10px;
						}
					}
				}
			}
		}
		&__offer
		{
			background: $colorOffWhite;
			border-bottom: 1px solid $colorDivider;
			border-top: 1px solid $colorDivider;
			font-size: $fontSize - 1;
			font-style: italic;
			line-height: $fontHeight - 1;
			margin: 5px 0 0 0;
			padding: 10px;
			&__inner
			{
				margin: 0;
				padding: 0;
			}
		}
	}
	&__totals
	{
		margin: 0;
		padding: 0;
		&__table
		{
			float: right;
			margin: 0;
			padding: 0;
			max-width: 50%;
			th, td
			{
				border: 0;
				margin: 0;
				padding: 10px 10px 0 10px;
				width: 100px;
			}
		}
		&__addresses
		{
			clear: both;
			margin: 0;
			padding: 15px 0 0 0;
		}
	}
}

// order summary
.m-order-summary
{
	margin: 0;
	padding: 0 0 15px 0;
	&__details
	{
		background: $colorOffWhite;
		border: 1px solid $colorDivider;
		margin: 0 0 15px 0;
		padding: 0;
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: -15px 0 0 -15px;
			padding: 15px;
		}
		&__group
		{
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: top;
			width: 25%;
			&__title
			{
				font-size: $fontSize - 2;
				font-weight: 600;
				line-height: $fontHeight - 2;
				margin: 0;
				padding: 0;
			}
			&__value
			{
				margin: 0;
				padding: 0;
			}
		}
	}
}

// checkout group
.m-checkout-group
{
	margin: 0;
	padding: 0;
	&__title
	{
		font-size: $fontSize;
		font-weight: 600;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 5px 0;
	}
	&__address
	{
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0;
		padding: 0;
	}
}

// tabs
.m-tabs
{
	&__tab
	{
		border: 1px solid $colorDivider;
		display: block !important;
		margin: 0 0 15px 0;
		padding: 15px;
	}
}


@import "base/fixes";