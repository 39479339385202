$cssversion: 26;
@import "base/config";

// Fonts
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('/fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/open-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('/fonts/open-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v27-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v27-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/open-sans-v27-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('/fonts/open-sans-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v27-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v27-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/open-sans-v27-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''), url('/fonts/open-sans-v27-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v27-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v27-latin-800.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v27-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v27-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/open-sans-v27-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/open-sans-v27-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans-v27-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/open-sans-v27-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans-v27-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans-v27-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/open-sans-v34-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/open-sans-v34-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans-v34-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/open-sans-v34-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans-v34-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans-v34-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

// colours
$colorBase: #2D2D2D;
$colorOffWhite: #f1f1f1;
$colorRed: #c10000;
$colorBlue: #09509E;
$colorPayPalBlue: #0070ba;
$colorYellow: #e78e4c;
$colorOrange: #FECE2F;
$colorFadedYellow: #F7D053;

// 50 shades
$colorGrey: #6d6d6d;
$colorGreyMid: #585858;
$colorGreyLight: #c9c9c9;
$colorDivider: #BCBCBC;
$colorBorder: #BCBCBC;
$colorGreyAnother: #D5D5D5;
$colorGreyAnother2: #F2F2F2;
$colorGreyAnother3: #e2e2e2;

// custom
$colorPrimary: #09509E;
$colorSecondary: #5eb1c8;
$colorBOC: #f39221;

// links
$colorLink: $colorPrimary;
$colorLinkHover: $colorBase;

// fonts
$fontSimple: Helvetica, Arial, Verdana, sans-serif;
$fontBase: 'Open Sans', $fontSimple; // 300, 400, 600, 700
$fontSize: 14px;
$fontHeight: 20px;

// responsive breakpoints
$maxPageWidth: 1270px;
$maxContentWidth: 800px;
$bpMobileLarge: 500px; // for 480px
$bpTablet: 620px; // for 600px
$bpTabletLarge: 788px; // for 768px
$bpDesktop: 980px; // for 960px;
$bpDesktopLarge: 1100px; // for 1080px
$bpLarge: $maxPageWidth + 20;
$bpLargeMobile: 375px;

// Section

$cSectionTitleBorderColor: #ddd;

// Recommended Extras

$cRecommendedExtrasImageBorderColor: #dedede;
$cRecommendedExtrasImageBorderHoverColor: #6d6d6d;
$cRecommendExtrasTitleColor: #4c4c4c;
$cRecommendExtrasTitleHoverColor: #09509E;
$cRecommendExtrasTitleBorderColor: #ddd;
$cRecommendExtrasPriceColor: #09509E;
$cRecommendExtrasPriceWasColor: #4c4c4c;
$cRecommendExtrasPriceSaleColor: $colorRed;
$cRecommendExtrasStatusMessageColor: $colorGreyMid;
$cRecommendExtrasScrollbarColor: #4c4c4c;
$cRecommendExtrasScrollbarTrackColor: #e1e1e1;

// Quantity

$cQuantityButtonBackgroundColor: $colorPrimary;
$cQuantityButtonBackgroundHoverColor: $colorBase;
$cQuantityInputBorderColor: #d7d7d7;

// Add to basket

$mAtbSidePanelWidth: 380px;
$mAtbSidePanelWidthTablet: 325px;
$mAtbPanelWidth: 860px;
$mAtbDesktopBp: 990px;
$mAtbWideBp: ($mAtbSidePanelWidth + $mAtbPanelWidth); // 1235
$mAtbDeepBp: 628px; // iphone 6/7/8 Plus - https://www.kylejlarson.com/blog/iphone-6-screen-size-web-design-tips/
$mAtbAnimationTime: 300ms;
$mAtbAnimationEasing: ease;
$mAtbHeaderHeight: 40px;
$mAtbHeaderHeightDeep: 100px;
$mAtbFooterHeight: 90px;
$mAtbFooterHeightDesktop: 90px;
$mAtbColorSuccess: #08a500;
$mAtbColorTitle: #333;
$mAtbColorDelivery: #333;
$mAtbColorPrice: #333;
$mAtbColorFilterBy: #333;
$mAtbColorBlue: #00abee;
$mAtbLoaderColor: $colorBlue;
$mAtbSaleColor: $colorRed;

// Loader

$cLoaderHighlightColor: #434343;
$cLoaderBackgroundColor: #f1f1f1;

// Shared Ratios

$ratioImageBannerWidth: 1270;
$ratioImageBannerHeight: 857;
$ratioImageProductWidth: 840;
$ratioImageProductHeight: 750;